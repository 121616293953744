import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeader from '../components/Layout/PageHeader'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import {Inner, InnerSkinny} from '../components/styles'
import ComparisonTableNew from '../components/CompareNew'

const CompareMattressesPremium = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 1204}) {
        title
        content
      }
      seo: wpgraphql {
        pageBy(pageId: 1204) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  return (
    <Layout location={location} flush>
      <SEO
        location={location}
        seo={data.seo.pageBy.seo}
        title="Compare Mattresses"
      />
      <PageHeader location={location} flush>
        {data.wp.title}
      </PageHeader>
      <div
        className="content"
        dangerouslySetInnerHTML={{__html: data.wp.content}}
      />
      <Container>
        <InnerSkinny>
          <h2 className="red">
            See the <strong>Difference</strong>
          </h2>
          <p>
            Although we merged the perfect design and business team. Few hurdles
            still remained. The main challenges we faced included: Sleeping
            Style Difference, Personal Preference and Individual Budget. Since
            catering for everyone is essential, our team decided to construct
            three different{' '}
          </p>
        </InnerSkinny>
        <Inner>
          <ComparisonTableNew
            pageSlug={'kooi-b-series-mattresses-comparison'}
          />
        </Inner>
      </Container>
    </Layout>
  )
}

export default CompareMattressesPremium

const Container = styled.div`
  padding: 80px 0;
  h2.red {
    margin: 0 0 50px 0 !important;
  }
`
